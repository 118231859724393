@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(
    92.23deg,
    #ff56f6 21.43%,
    #b936ee 50.63%,
    #3bace2 100%,
    #406aff 117.04%
  );
  padding-top: 15px;
  scroll-behavior: smooth;
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
  border: solid 2px rgb(255, 255, 255);
  animation: animate 3s linear infinite;
}
.btn2 {
  background: linear-gradient(
    92.23deg,
    #ff56f6 21.43%,
    #b936ee 50.63%,
    #3bace2 100%,
    #406aff 117.04%
  );
  padding-top: 15px;
  scroll-behavior: smooth;
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
  border: solid 1px rgb(255, 255, 255);
}
.btnBanner {
  background: linear-gradient(
    92.23deg,
    #ff56f6 21.43%,
    #b936ee 50.63%,
    #3bace2 100%,
    #406aff 117.04%
  );
  padding-top: 15px;
  scroll-behavior: smooth;
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
  border: solid 1px rgb(255, 255, 255);
}

@keyframes animate 
{
  0%
  {
    box-shadow: 0 0 0 0 rgba(47, 163, 199, 0), 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  20%
  {
    box-shadow: 0 0 0 50px rgba(255, 109, 74, 0), 0 0 0 0 rgba(42, 172, 228, 0.768);
  }
  50%
  {
    box-shadow: 0 0 0 50px rgba(255, 109, 74, 0), 0 0 0 30px rgba(255, 109, 74, 0);
  }
  100%
  {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 30px rgba(255, 109, 74, 0);
  }
  

}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}
.btn2:hover {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}
.icon1:hover,
.icon2:hover,
.icon3:hover {
  color: rgb(204, 117, 236);
  border-radius: 100px;
  transform: scale(1.1);
  transition: 0.2s all ease-in-out;
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}


.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

.contact p {
  display: flex;
  align-items: center;
  line-height: 1.8;
  text-align: justify;
  margin-right: 55px;
}
/* OVERLAY */
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    z-index: 1000;
}
/* END OVERLAY */

/*MODAL*/
.modalContainer {
  max-width: 650px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background: linear-gradient(90deg, rgba(12,0,36,1) 0%, rgba(121,9,86,1) 35%, rgba(81,0,255,1) 100%); 
  box-shadow: 1rem 3rem 5rem rgba(34, 176, 192, 0.161);
  border-radius: 20px;
  border: 2px solid rgb(255, 255, 255);
  z-index: 5;
}

.imgmodal {
  width: 230px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 1px solid rgb(255, 255, 255);
}
.imgmodal1 {
  width: 230px;
  padding: 25px 0px; 
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 1px solid rgb(255, 255, 255);
}
.imgmodal3 {
  width: 250px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 1px solid rgb(255, 255, 255);
}

.modalRight {
  width: 100%;
}

.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.closeBtn:hover {
  color: rgb(5, 5, 5);
}

.content{
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0rem;
  padding: 1rem 3rem;
  margin-bottom: 10px;
}
  
.content p {
  color: rgb(224, 253, 255);
  /* padding-top: 20px; */
  padding: 15px 0;
  font-weight: 500;
}

.socialModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  column-gap: 15px;
  position: absolute;
  bottom: 0;
  margin: 10px 0;
}
.iconWork {
  display: flex;
  column-gap: 15px;
  /* justify-content: center;
  align-items: center; */
  margin-bottom: 10px ;
  padding: 10px 0;
}
/* END MODAL */

/* MEDIA */
@media screen and (max-width: 500px) {
  .modalContainer {
    flex-direction: column;
    top: 5%;
    left: 0;
    transform: none;
    width: 96%;
    height: 80vh;
  }

  .imgmodal,
  .imgmodal3 {
    width: 50%;
    height: 250px;
    border: none;
    border-radius: 20px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  }
  .imgmodal1 {
    border: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 33%;
  }
  .closeBtn {
    position: absolute;
  }
  .btnBanner {
    font-size: 15px;
    padding: 15px 25px;
  }
}
/* END MEDIA */

/* LAYER */
@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn, .btn2, .btnBanner {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
/* END LAYER */
